import { type FC } from 'react';
import { Link } from 'react-router-dom';

const Error: FC = () => {
  return (
    <div className={'container'}>
      <div className="flex justify-center mt-36">
        <h1 className={'text-2xl'}>Errorが発生しました</h1>
      </div>
      <div className="flex justify-center mt-10">
        <div className={'text-center'}>
          <p className={'text-lg'}>時間をおいて、再度お試しください。</p>
        </div>
      </div>
      <div className="flex justify-center mt-6">
        <p>
          <Link to="/">Topへ戻る</Link>
        </p>
      </div>
    </div>
  );
};

export default Error;
